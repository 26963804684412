import React from 'react';
import { Link } from 'react-router-dom';
import './NotFoundPage.css';
import Footer from '../../components/Footer/Footer';

const NotFoundPage = () => {
  return (
    <>
    <div className="not-found-page">
      <h1>404</h1>
      <h2>Promiň :(</h2>
      <p>Stránka, kterou hledáš neexistuje...</p>
      <Link to="/" className="home-link">Zpátky na úvodní stránku</Link>
    </div>
    <Footer/>
    </>
  );
};

export default NotFoundPage;