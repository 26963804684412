import React from 'react';
import './ActorPopup.css';

const ActorPopup = ({ show, onClose, actor }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>x</button>
        <div className="popup-body">
          <h2>{actor.name}</h2>
          <img className="main-image" src={actor.mainImage} alt={actor.name} loading='lazy'/>
          {actor.paragraphs.map((paragraph, index)=> (
            <p>{paragraph}</p>
          ))}
          <div className="additional-photos">
            {actor.photos.map((photo, index) => (
              <img key={index} src={photo} alt={`${actor.name} ${index}`} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActorPopup;