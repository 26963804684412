import React, {useEffect} from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import "./RepertoirePage.css";
import Navbar from "../../components/NavBar/Navbar";
import Ruzenka from "../../assets/reprertoire/ruzenka.webp";
import Honza from "../../assets/reprertoire/honza.webp";
import NaPavlaci from "../../assets/reprertoire/napavlaci.webp";
import Spolu from "../../assets/reprertoire/spolu.webp";
import PotrestanaPycha from "../../assets/reprertoire/potrestanapycha.webp";
import VanocniZemekoule from "../../assets/reprertoire/vanocniZemekoule.webp";
import ScrollButton from "../../components/ScrollTopButton/ScrollButton";
import Honza1 from "../../assets/archive/honza/160.webp";
import Honza2 from "../../assets/archive/honza/161.webp";
import Honza3 from "../../assets/archive/honza/162.webp";
import Honza4 from "../../assets/archive/honza/163.webp";
import Honza5 from "../../assets/archive/honza/164.webp";
import Honza6 from "../../assets/archive/honza/165.webp";
import Honza7 from "../../assets/archive/honza/166.webp";
import Honza8 from "../../assets/archive/honza/167.webp";
import Honza9 from "../../assets/archive/honza/168.webp";
import Honza10 from "../../assets/archive/honza/169.webp";
import Honza11 from "../../assets/archive/honza/170.webp";
import Honza12 from "../../assets/archive/honza/171.webp";
import Honza13 from "../../assets/archive/honza/172.webp";
import Honza14 from "../../assets/archive/honza/175.webp";
import Honza15 from "../../assets/archive/honza/176.webp";
import Honza16 from "../../assets/archive/honza/177.webp";
import Honza17 from "../../assets/archive/honza/178.webp";
import Honza18 from "../../assets/archive/honza/179.webp";
import Honza19 from "../../assets/archive/honza/182.webp";
import Honza20 from "../../assets/archive/honza/183.webp";
import Honza21 from "../../assets/archive/honza/184.webp";
import Honza22 from "../../assets/archive/honza/185.webp";
import Honza23 from "../../assets/archive/honza/186.webp";
import Honza24 from "../../assets/archive/honza/187.webp";
import Honza25 from "../../assets/archive/honza/188.webp";
import Honza26 from "../../assets/archive/honza/189.webp";
import Ruzenka1 from "../../assets/archive/ruzenka/1.webp";
import Ruzenka2 from "../../assets/archive/ruzenka/2.webp";
import Ruzenka3 from "../../assets/archive/ruzenka/3.webp";
import Ruzenka4 from "../../assets/archive/ruzenka/4.webp";
import Pavlac1 from "../../assets/archive/pavlac/1.webp";
import Pavlac2 from "../../assets/archive/pavlac/2.webp";
import Pavlac3 from "../../assets/archive/pavlac/3.webp";
import Pavlac4 from "../../assets/archive/pavlac/4.webp";
import Pavlac5 from "../../assets/archive/pavlac/5.webp";
import Pavlac6 from "../../assets/archive/pavlac/6.webp";
import Pavlac7 from "../../assets/archive/pavlac/7.webp";
import Pavlac9 from "../../assets/archive/pavlac/9.webp";
import Pavlac11 from "../../assets/archive/pavlac/11.webp";
import Pavlac12 from "../../assets/archive/pavlac/12.webp";
import Pavlac13 from "../../assets/archive/pavlac/13.webp";
import Pavlac14 from "../../assets/archive/pavlac/14.webp";
import Plavacek1 from "../../assets/plavacek/1.webp";
import Plavacek2 from "../../assets/plavacek/2.webp";
import Plavacek3 from "../../assets/plavacek/3.webp";
import Plavacek4 from "../../assets/plavacek/4.webp";
import Plavacek5 from "../../assets/plavacek/5.webp";
import Plavacek6 from "../../assets/plavacek/6.webp";
import Plavacek7 from "../../assets/plavacek/7.webp";
import Plavacek8 from "../../assets/plavacek/8.webp";
import ImageGalery from "../../components/ImageGalery/ImageGalery";
import SlideShow from "../../components/SlideShow/SlideShow";
import Footer from "../../components/Footer/Footer";

function RepertoirePage() {
  const honzaImages = [
    { original: Honza1, thumbnail: Honza1 },
    { original: Honza2, thumbnail: Honza2 },
    { original: Honza3, thumbnail: Honza3 },
    { original: Honza4, thumbnail: Honza4 },
    { original: Honza5, thumbnail: Honza5 },
    { original: Honza6, thumbnail: Honza6 },
    { original: Honza7, thumbnail: Honza7 },
    { original: Honza8, thumbnail: Honza8 },
    { original: Honza9, thumbnail: Honza9 },
    { original: Honza10, thumbnail: Honza10 },
    { original: Honza11, thumbnail: Honza11 },
    { original: Honza12, thumbnail: Honza12 },
    { original: Honza13, thumbnail: Honza13 },
    { original: Honza14, thumbnail: Honza14 },
    { original: Honza15, thumbnail: Honza15 },
    { original: Honza16, thumbnail: Honza16 },
    { original: Honza17, thumbnail: Honza17 },
    { original: Honza18, thumbnail: Honza18 },
    { original: Honza19, thumbnail: Honza19 },
    { original: Honza20, thumbnail: Honza20 },
    { original: Honza21, thumbnail: Honza21 },
    { original: Honza22, thumbnail: Honza22 },
    { original: Honza23, thumbnail: Honza23 },
    { original: Honza24, thumbnail: Honza24 },
    { original: Honza25, thumbnail: Honza25 },
    { original: Honza26, thumbnail: Honza26 },
  ];

  const ruzenkaImages = [
    { original: Ruzenka1, thumbnail: Ruzenka1 },
    { original: Ruzenka2, thumbnail: Ruzenka2 },
    { original: Ruzenka3, thumbnail: Ruzenka3 },
    { original: Ruzenka4, thumbnail: Ruzenka4 },
  ];

  const pavlacImages = [
    { original: Pavlac1, thumbnail: Pavlac1 },
    { original: Pavlac2, thumbnail: Pavlac2 },
    { original: Pavlac3, thumbnail: Pavlac3 },
    { original: Pavlac4, thumbnail: Pavlac4 },
    { original: Pavlac5, thumbnail: Pavlac5 },
    { original: Pavlac6, thumbnail: Pavlac6 },
    { original: Pavlac7, thumbnail: Pavlac7 },
    { original: Pavlac9, thumbnail: Pavlac9 },
    { original: Pavlac11, thumbnail: Pavlac11 },
    { original: Pavlac12, thumbnail: Pavlac12 },
    { original: Pavlac13, thumbnail: Pavlac13 },
    { original: Pavlac14, thumbnail: Pavlac14 },
  ];

  const plavacekImages = [
    { original: Plavacek1, thumbnail: Plavacek1 },
    { original: Plavacek2, thumbnail: Plavacek2 },
    { original: Plavacek3, thumbnail: Plavacek3 },
    { original: Plavacek4, thumbnail: Plavacek4 },
    { original: Plavacek5, thumbnail: Plavacek5 },
    { original: Plavacek6, thumbnail: Plavacek6 },
    { original: Plavacek7, thumbnail: Plavacek7 },
    { original: Plavacek8, thumbnail: Plavacek8 },
  ];

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
    <Helmet>
      <title>Repertoár | Divadlo Di</title>
      <meta name='description' content='Zajímá tě, co všechno umíme zahrát? Prohlídni si námi zpracované hry.'/>
      <meta name='keywords' content='repertoar, repertoár, vystoupení, divadlo, divadlo DI, DI, divadelní hry'/>
      <link rel='canonical' href='https://www.divadlodi.cz/#/repertoar' />
    </Helmet>
      <div className="repertoire-page">
        <Navbar />
        <div className="slideshow">
          <SlideShow />
        </div>
        <div className="section">
          <h1 id="forKids">Představení pro děti</h1>
      
          <div className="subsection">
            <img src={Ruzenka} alt="O Šípkové Růžence" loading="lazy"/>
            <div>
              <Link to={"/ruzenka#info"}>
                <h2>O šípkové Růžence</h2>
              </Link>
              <p>
                Srdečně Vás zveme na obnovenou premiéru pohádky O
                Šípkové Růžence nově s hercem Adamem Horkým.
              </p>
              <Link to={"/ruzenka#info"}>
                Více informací o představení ▶
              </Link>
            </div>
          </div>

          <div className="subsection">
            <img src={Honza} alt="Jak Honza ke štěstí přišel" loading="lazy"/>
            <div>
              <Link to={"/honza#info"}>
                <h2>Jak Hozna ke štěstí přišel</h2>
              </Link>
              <p>
                Srdečně Vás zveme ke zhlédnutí veselé pohádky, která je vhodná
                pro předškolní děti a děti prvního stupně.
              </p>
              <Link to={"/honza#info"}>
                Více informací o představení ▶
              </Link>
            </div>
          </div>

          <div className="subsection">
            <img src={PotrestanaPycha} alt="Potrestaná pýcha" loading="lazy"/>
            <div>
              <Link to={"/potrestanaPycha#info"}>
                <h2>Potrestaná pýcha</h2>
              </Link>
              <p>
                Srdečně Vás zveme ke zhlédnutí veselé pohádky o pyšné princezně.
              </p>
              <Link to={"/potrestanaPycha#info"}>
                Více informací o představení ▶
              </Link>
            </div>
          </div>

          <div className="subsection">
            <img src={VanocniZemekoule} alt="Vánoční Zeměkoule" loading="lazy"/>
            <div>
              <Link to={"/vanocniZemekoule#info"}>
                <h2>Vánoční Zeměkoule</h2>
              </Link>
              <p>
              Srdečně Vás zveme ke zhlédnutí činoherní, laskavé a humorné pohádky jednoho herce.
              </p>
              <Link to={"/vanocniZemekoule#info"}>
                Více informací o představení ▶
              </Link>
            </div>
          </div>

        </div>

        {/* Second Section: 2 subsections */}
        <div className="section">
          <h1 id="forAdult">Představení pro dospělé</h1>
          <div className="subsection">
            <img src={Spolu} alt="Spolu" loading="lazy"/>
            <div>
            <Link to={"/spolu#info"}>
                <h2>SPOLU</h2>
              </Link>
              <p>
                Něžná komedie o nehynoucí lásce v manželství propletená spoustou
                slovních hříček. Chcete-li věřit na velkou lásku na celý život,
                a přitom se i zasmát, pak je tu pro vás hra Spolu.
              </p>
              <Link to={"/spolu#info"}>
                Více informací o představení ▶
              </Link>
            </div>
          </div>
          <div className="subsection">
            <img src={NaPavlaci} alt="Na Pavlači" loading="lazy"/>
            <div>
            <Link to={"/naPavlaci#info"}>
                <h2>Na Pavlači</h2>
              </Link>
              <p>
                Nahlédněte pod pokličku dvou žen před padesátkou a zjistěte, co
                jim nejvíce leží na srdci - vrásky, špíčky, práce nebo muži? To
                vše a mnohem více můžete shlédnout v komedii Na pavlači
                provoněné kávou...
              </p>
              <Link to={"/naPavlaci#info"}>
                Více informací o představení ▶
              </Link>
            </div>
          </div>
        </div>

        {/* Third Section: 3 subsections */}
        <div className="section">
          <h1 id="archive">Archiv</h1>
          <h2>Představení s Monikou Münsterovou</h2>
          <div className="gallery-subsection first-subsection">
            <ImageGalery images={plavacekImages} h2={"PLAVÁČEK"} />
          </div>
          <div className="gallery-subsection">
            <ImageGalery images={ruzenkaImages} h2={"O ŠÍPKOVÉ RŮŽENCE"} />
          </div>
          <div className="gallery-subsection">
            <ImageGalery
              images={honzaImages}
              h2={"JAK HONZA KE ŠTĚSTÍ PŘIŠEL"}
            />
          </div>
          <div className="gallery-subsection">
            <ImageGalery images={pavlacImages} h2={"NA PAVLAČI"} />
          </div>
        </div>
      </div>
      <ScrollButton />
      <Footer/>
    </>
  );
}

export default RepertoirePage;
